// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../../../src/pages/kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */),
  "component---src-pages-sluzby-fyzicke-osoby-js": () => import("./../../../src/pages/sluzby/fyzicke-osoby.js" /* webpackChunkName: "component---src-pages-sluzby-fyzicke-osoby-js" */),
  "component---src-pages-sluzby-spolecnosti-js": () => import("./../../../src/pages/sluzby/spolecnosti.js" /* webpackChunkName: "component---src-pages-sluzby-spolecnosti-js" */)
}

